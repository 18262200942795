import React from 'react';
import './PrivacyPolicy.css';
import { Helmet } from 'react-helmet';

function PrivacyPolicy() {
    return (
        <div className="privacy-policy-container">

            <Helmet>
                <title>كورا - سياسة الخصوصية </title>
            </Helmet>

            <div className='up-date'>
                <p className='date-pr'>
                    تاريخ السريان: 2024/01/01
                </p>
                <p className='date-pr'>
                    آخر تحديث: 2024/01/01
                </p>
            </div>

            <h1 className='title-pr'>سياسة الخصوصية منصه كورا لتقنية المعلومات</h1>
            <p className='content-pr'>
                مرحبًا بك في منصة كورا, نحن نهتم بخصوصيتك وأمان معلوماتك الشخصية
                تحتفظ كورا بالحق في إجراء تغييرات على هذه السياسة في أي وقت وفقاً لتقديرها، وتحديث هذه الصفحة بناء على ذلك، ويتم نشر التعديل وإشعار المستخدمين المسجّلين في كورا قبل مدة (أسبوع) على الأقل من تاريخ تطبيقه
                ويعدّ وصولك إلى موقع كورا الإلكتروني، أو استخدام أي خاصية من خواصهما؛ قبولاً وموافقة منك على الالتزام بسياسة الخصوصية فيما يلي شرح لكيفية جمع واستخدام المعلومات الخاصة بك عند استخدام منصه كورا. من فضلك اقرأ هذه السياسة بعناية

            </p>

            <h2 className='sub-pr'>التعريفات:</h2>
            <p className='content-pr'>
                يعتبر التمهيد أعلاه جزءاً لا يتجزأ من اتفاقية التاجر المبرمة محل تقديم الخدمة كما تجدون أدناه الدلالات والتعريفات للعبارات الرئيسية المستخدمة في الشروط والاحكام هذه
                يقصد بالكلمات والعبارات الآتية المعاني المبينة إلى جانب كل منها ما لم يتضح من صراحة النص ويقتضي سياق الكلام غير ذلك:
                الأطراف": المقصود بها شركة كورا ويعرف (بمزود الخدمة) وطالب الخدمة ويعرف (بالتاجر).
                الخدمة": هي خدمة الدفع الالكتروني المقدمة من مزود خدمة الدفع الالكترونية.
                ."الرسوم": المقصود بها المبالغ المستحقة لمزود الخدمة من التاجر بموجب الشروط والاحكام.
                ."المبالغ المستحقة": المقصود بها مجموع تكاليف الخدمة والعائد المالي لكلا الطرفين.
            </p>

            <h2 className='sub-pr'>المعلومات التي نجمعها</h2>
            <h2 className='sub-pr'>عند استخدام منصة كورا، قد نقوم بجمع المعلومات التالية:</h2>
            <p className='content-pr'>
                1-	المعلومات الشخصية: قد نجمع معلومات شخصية مثل الاسم، وعنوان البريد الإلكتروني، ورقم الهاتف، ومعلومات الفوترة.
            </p>
            <p className='content-pr'>
                2-	لماذا نجمع المعلومات الشخصية: نقوم بجمع واستخدام البيانات الشخصية وفقا لمتطلبات وظائف وخدمات أعمالنا. السبب الرئيسي لجمع معلومات التعريف الشخصية هو الامتثال لالتزاماتنا القانونية والتنظيمية بموجب قانون حماية البيانات الشخصية في المملكة العربية السعودية (PDPL) واللوائح الأخرى المعمول بها. نحن نضمن معالجة البيانات الشخصية بشكل قانوني وعادل وشفاف ، فقط للأغراض المحددة في هذه السياسة
            </p>
            <p className='content-pr'>
                3-	موضوع البيانات: الشخص الطبيعي الذي يمكن التعرف عليه هو الشخص الذي يمكن تحديده ، بشكل مباشر أو غير مباشر ، بالرجوع إلى معرف مثل الاسم أو بيانات الموقع.
            </p>
            <p className='content-pr'>
                4-	بيانات الدفع: إذا قمت بالشراء عبر التطبيق أو الموقع، فسنحتاج إلى بعض المعلومات المالية لتمكين عملية الدفع ومعالجة الطلب تقوم كورا وشركة ميسر المالية بمعالجة البيانات المالية والمعاملات المتعلقة بالبيانات الشخصية حول حاملي البطاقات/المشترين ومعاملاتهم، والتي قد تشمل الاسم الكامل، وعنوان البريد الإلكتروني، ورقم الهاتف، ورقم بطاقة الائتمان، وتاريخ انتهاء صلاحيتها، وقيمة التحقق الخاصة بالبطاقة، ورقم الهوية الوطنية، ولكن لا تقوم كورا بتخزين رقم بطاقة الائتمان واي بيانات متعلقة بها او معرفتها، حيث ان عملية الخصم الفعلية من حاملي البطاقات وأي معاملات خاصة ببطاقات الائتمان تتم عبر وسيط مالي (طرف ثالث) مرخص من البنك المركزي.
            </p>
            <p className='content-pr'>
                5-	تقدر شركة كورا بياناتك الشخصية وتلتزم بمعالجتها بمسؤولية ووفقا لقانون حماية البيانات الشخصية في المملكة العربية السعودية (PDPL) وقوانين ولوائح حماية البيانات الأخرى المعمول بها. وتشمل هذه في المقام الأول اللوائح المؤقتة للمكتب الوطني لإدارة البيانات في المملكة العربية السعودية بشأن حماية البيانات الشخصية ("اللوائح المؤقتة") وغيرها من القوانين واللوائح التي يشار إليها مجتمعة باسم "القوانين المعمول بها".
            </p>

            <h2 className='sub-pr'>أسباب جمع البيانات:</h2>
            <p className='content-pr'>
                الغرض الرئيس من جمع كورا لأي بيانات هو ضمان توفير أفضل الخدمات لمستخدميها. وتشمل الأغراض التي يتم جمع البيانات من أجلها، على سبيل المثال لا الحصر، ما يأتي:            </p>
            <p className='content-pr'>
                - الرد على أي استفسارات.
            </p>
            <p className='content-pr'>
                - النظر في الشكاوى.
            </p>
            <p className='content-pr'>
                - إدارة التعاملات بين البائع والمشتري وحل المشكلات التي تظهر خلال تقديم الخدمة.
            </p>
            <p className='content-pr'>
                - تيسير عمل حملات البريد الإلكتروني التي يقوم بها قسم المبيعات والتسويق بوفي.
            </p>
            <p className='content-pr'>
                - تحسين خدمة الإعلانات.
            </p>
            <p className='content-pr'>
                - صميم تجربة المستخدم.
            </p>
            <p className='content-pr'>
                - الوفاء بالتزاماتنا تجاه المستخدمين بموجب اتفاقية شروط وأحكام استخدام كورا.
            </p>
            <p className='content-pr'>
                - الوفاء بالتزاماتنا بموجب الأنظمة والقوانين السعودية.
            </p>
            <p className='content-pr'>
                - إبلاغ المستخدم بالتغييرات التي تطرأ على أحد أنظمتنا الأساسية أو جميعها.
            </p>
            <p className='content-pr'>
                معلومات الجهاز: نجمع معلومات حول جهازك مثل نوع الجهاز، ونظام التشغيل، وعنوان IP، ومتصفح الويب. كيف نستخدم المعلومات
            </p>

            <h2 className='sub-pr'>نحن نستخدم المعلومات التي نجمعها لأغراض متنوعة، بما في ذلك:</h2>
            <p className='content-pr'>
                - تسهيل عمليات الشراء.
            </p>
            <p className='content-pr'>
                - تخصيص تجربتك على المنصة وتقديم محتوى مخصص.
            </p>
            <p className='content-pr'>
                - تحسين خدماتنا وأمان منصتنا.
            </p>
            <p className='content-pr'>
                - يتم تجميع النماذج من خلال إكمال النماذج عبر الإنترنت على منصتنا ، مثل نماذج التسجيل والمبيعات.‏
            </p>
            <p className='content-pr'>
                - إبلاغ عن المشكلات: المعلومات التي تقدمها أو التي نحصل عليها عند الإبلاغ عن مشكلة في منصتنا.‏
            </p>
            <p className='content-pr'>
                - بيانات تفاعل العملاء: البيانات المتبادلة مع ممثلي خدمة العملاء لدينا من خلال مواقع الويب أو وسائل التواصل الاجتماعي أو قنوات
            </p>
            <p className='content-pr'>
                - تفاصيل المعاملة: سجلات المعاملات التي قد تجريها من خلال منصتنا.‏
            </p>

            <h2 className='sub-pr'>مشاركة المعلومات</h2>
            <p className='content-pr'>
                1-	نحن لا نبيع أو نشارك معلوماتك الشخصية مع أطراف ثالثة دون موافقتك الصريحة، إلا إذا كان ذلك ضروريًا لتقديم خدماتك مثل مشاركة بيانات الدفع مع خدمات المعالجة المالية             </p>
            <p className='content-pr'>
                2-	يقتصر الدخول إلى بيانات التجار على الموظفين المخولين بذلك والذين تتوفر لديهم أسباب عملية محددة.            </p>
            <p className='content-pr'>
                3-	تضمن أنه قد اتخذت الاحتياطات اللازمة لضمان أن أنظمتك الإلكترونية آمنة من الاختراق أو التسلل من قبل أي طرف ثالث غير مصرح له. وفي حال تم اختراق نظامك وامتلاك طرف ثالث غير مصرح له حق الوصول إلى بيانات حامل البطاقة أو بيانات المعاملات، يجب عليك إخطار مزود خدمة الدفع الالكتروني على الفور بمثل هذا الاختراق ويتخذ الاحتياطات التي قد تكون ضرورية لمنع مثل هذه الانتهاكات من الحدوث في المستقبل.            </p>

            <h2 className='sub-pr'>حقوق الوصول والتحكم</h2>
            <p className='content-pr'>
                لديك حق الوصول إلى معلوماتك الشخصية وتحديثها وحذفها عند الضرورة. يمكنك أيضًا تعيين تفضيلات الخصوصية الخاصة بك في إعدادات التطبيق أو الموقع. لديك حق الوصول إلى معلوماتك الشخصية وتحديثها وحذفها عند الضرورة. يمكنك أيضًا تعيين تفضيلات الخصوصية الخاصة بك في إعدادات التطبيق أو الموقع.            </p>

            <h2 className='sub-pr'>أمان المعلومات</h2>
            <p className='content-pr'>
                1-	نحن نعمل بجد لحماية المعلومات الشخصية الخاصة بك من الوصول غير المصرح به واستخدامه أو الكشف عنه نستخدم إجراءات أمان تقنية وإدارية لضمان سلامة بياناتك
            </p>
            <p className='content-pr'>
                2-	يقتصر الدخول إلى بيانات التجار على الموظفين المخولين بذلك والذين تتوفر لديهم أسباب عملية محددة.
            </p>
            <p className='content-pr'>
                3-	لا يقوم مزود الخدمة بتزويد أي طرف ثالث بالمعلومات الخاصة بك إلا وفقاً للأنظمة المعمول بها في المملكة العربية وهي الأنظمة التي تحكم كافة تعاملاتنا المشتركة معكم.
            </p>
            <p className='content-pr'>
                4-	تضمن أنه قد اتخذت الاحتياطات اللازمة لضمان أن أنظمتك الإلكترونية آمنة من الاختراق أو التسلل من قبل أي طرف ثالث غير مصرح له. وفي حال تم اختراق نظامك وامتلاك طرف ثالث غير مصرح له حق الوصول إلى بيانات حامل البطاقة أو بيانات المعاملات، يجب عليك إخطار مزود خدمة الدفع الالكتروني على الفور بمثل هذا الاختراق ويتخذ الاحتياطات التي قد تكون ضرورية لمنع مثل هذه الانتهاكات من الحدوث في المستقبل.
            </p>

            <h2 className='sub-pr'>مشاركة البيانات لمقدمي الخدمة والأطراف الثالثة:</h2>
            <p className='content-pr'>
                قد تشارك كورا بياناتك مع عدد محدود من مزودي الخدمة بالقدر اللازم لتقديم الخدمات، مثل خدمات التحقق من الهوية واستضافة البنية التحتية وتحليل البيانات ومعالجة خدمات الدفع وخدمة العملاء وإرسال الرسائل الإلكترونية، إذ قد يحتاج مقدمي الخدمة هؤلاء إلى الوصول إلى البيانات الشخصية للتمكّن من أداء مهامهم الضرورية لتقديم كورا لخدماته, كما يجوز لكورا مشاركة البيانات مع أطراف ثالثة من شركائه عندما يكون ذلك ضرورياً لتوفير الخدمات للمستخدمين مثل البنوك، وقد نكشف كذلك- عن بياناتك وفق ما تقتضيه الأنظمة للجهات الحكومية والأمنية التي تملك صلاحية طلب مثل تلك البيانات.
            </p>

            <h2 className='sub-pr'>مدة الاحتفاظ بالبيانات</h2>
            <p className='content-pr'>
                •	تحتفظ كورا ببياناتك طالما كان ذلك ضروريًا على نحو معقول أو مسموح به نظامًا. وسوف نضمن التخلص من البيانات بطريقة آمنة عندما لا يعد هناك حاجة إليها أو بناءً على طلبك. وعند الحاجة إلى إتلاف البيانات، فإن كورا سيتأكد من التخلص منها بطريقة آمنة.
            </p>

            <h2 className='sub-pr'>حقوق المستخدم فيما يتعلق بالبيانات</h2>
            <p className='content-pr'>
                إذا كنت ترغب في الحصول على أي من حقوقك المذكورة أدناه، فإنه ولأغراض أمنية، قد نطلب منك بيانات إضافية لتأكيد هويتك، وذلك قبل الكشف عن بياناتك الشخصية، وسيتوجب علينا الوفاء بجميع تلك الحقوق في غضون فترة زمنية معقولة بما يتماشى مع الأنظمة السارية. وقد لا نتمكن دائمًا من معالجة طلبك بشكل كامل في بعض الحالات، على سبيل المثال:
            </p>
            <p className='content-pr'>
                - إذا كانت المعالجة ستؤثر على السرية التي نلتزم بها تجاه الآخرين.
            </p>
            <p className='content-pr'>
                - إذا كان لكورا الحق نظامًا في التعامل مع الطلب بطريقة مختلفة.
            </p>
            <p className='content-pr'>
                - إذا كان طلبك يتضمن حذف البيانات المطلوبة للامتثال للمتطلبات النظامية.
            </p>
            <p className='content-pr'>
                وفي جميع الأحوال، سنبذل قصارى جهدنا لتلبية طلبك وفق النظام. وفي حال احتجنا إلى مدة زمنية إضافية بسبب درجة تعقيد الطلب، سوف نبلغك بذلك على الفور.
                بصفتك مالكًا للبيانات، فإنه يحق لك الآتي:
            </p>
            <p className='content-pr'>
                - الحق في الوصول الى البيانات الخاصة بك.
            </p>
            <p className='content-pr'>
                - الحق في تعديل/تحديث البيانات الخاصة بك.
            </p>
            <p className='content-pr'>
                - الحق في طلب اتلاف البيانات الخاصة بك.
            </p>
            <p className='content-pr'>
                - الحق في معرفة كيف نعالج بياناتك ولماذا.
            </p>
            <p className='content-pr'>
                - أي حق آخر منصوص عليه في الأنظمة ذات العلاقة.
            </p>

            <h2 className='sub-pr'>أين نقوم بتخزين بياناتك الشخصية:</h2>
            <p className='content-pr'>
                •	فترة الاحتفاظ: سيتم تخزين المعلومات الشخصية فقط طالما كان ذلك ضروريا لتحقيق الأغراض التي تم جمعها من أجلها.
            </p>
            <p className='content-pr'>
                •	ستكون بياناتك الشخصية مخزنة داخل المملكة العربية السعودية.
            </p>
            <p className='content-pr'>
                •	الاحتفاظ بها للوفاء بأي التزامات تنظيمية أو قانونية إلزامية
            </p>

            <h2 className='sub-pr'>تغييرات على سياسة الخصوصية</h2>
            <p className='content-pr'>
                قد نقوم بتحديث سياسة الخصوصية بين الحين والآخر لتعكس التغييرات في أنشطتنا وتجارب المستخدمين. ستكون أي تغييرات قائمة فقط بعد نشرها على هذه الصفحة وتحديث وتغيير الشروط والاحكام عن طريق نشر التحديثات والتغييرات على موقعنا كما يُنصح بزيارة الموقع من وقت لآخر للاطلاع على أي تحديث قد يؤثر عليك، اذ يتوجب عليك قراءة جميع البنود الواردة في الشروط والأحكام والموافقة عليها قبل أن تصبح مستخدمًا رسميًا في كورا و يرجى قراءة “الشروط والأحكام” للحصول على الصورة الكاملة للمتطلبات القانونية الخاصة بك.
            </p>

            <h2 className='sub-pr'>الاتصال بنا</h2>
            <p className='content-pr'>
                إذا كان لديك أي أسئلة أو استفسارات حول سياسة الخصوصية، يُرجى التواصل بنا عبر البريد الإلكتروني: Hi@cora.sa
            </p>

            <h1 className='title-pr'>اتفاقية شروط وأحكام استخدام كورا</h1>
            <h2 className='sub-pr'>أولاً: مقدمة وأحكام تمهيدية</h2>
            <p className='content-pr'>
                1-	تحدد هذه الاتفاقية حقوقك والتزاماتك كمستخدم فيما يتعلق بالخدمة المقدمة من كورا، ويعد وصولك إلى الخدمة مشروطًا بقبولك وامتثالك للشروط والأحكام الواردة في هذه الاتفاقية، وفي حال عدم الموافقة على أي من هذه الشروط والأحكام فيجب عليك التوقف فورًا عن الوصول إلى الخدمة أو استخدامها
            </p>
            <p className='content-pr'>
                2-	يحق لكورا التعديل على هذه الاتفاقية في أي وقت من الأوقات، ويتم نشر التعديل وإشعار المستخدمين المسجّلين في كورا به قبل مدة (أسبوع) على الأقل من تاريخ تطبيقه.
            </p>

            <h2 className='sub-pr'>ثانياً: المعاملات المحظورة</h2>
            <p className='content-pr'>
                1-	تشمل المعاملات المحظورة -التي لا يجوز استخدام كورا لأجلها- كل ما ينطوي على عنصر غير قانوني أو يهدف لأي غرض غير قانوني، أو يتضمن أي مادة مخالفة للأنظمة أو القوانين السارية أو الآداب العامة، أو يشتمل على ذخيرة أو سلاح ناري، أو يحتوي على برامج مقرصنة أو مقاطع فيديو تنتهك الأعمال المحمية بحقوق الطبع والنشر، أو أي عقاقير غير مشروعة أو مواد مخدرة أو مسكرة، أو أي معاملات أخرى محظورة داخل المملكة العربية السعودية، أو أية تعاملات مع أشخاص أو جهات يُحظر التعامل معهم وفق القوانين والأنظمة السعودية.
            </p>
            <p className='content-pr'>
                2-	يوافق كل مستخدم على تعويض كورا عن أي ضرر ناجم بشكل مباشر أو غير مباشر عن معاملة محظورة.
            </p>

            <h2 className='sub-pr'>ثالثَا: التسويات</h2>
            <p className='content-pr'>
                1-	يتم معالجة المدفوعات عن طريق شركة ميسر المالية وسيتم خصم الرسوم من إجمالي المبلغ الذي يتم تحويله إلى الحساب البنكي الخاص بك. قد يتم تطبيق رسوم إضافية لبعض طرق الدفع.
            </p>
            <p className='content-pr'>
                2-	يجب أن تفحص جميع المعاملات على الفور، وأن تخطر مزود الخدمة في غضون ثلاثين (30) يوم عمل عن أي خطأ. ما لم يتم إخطار مزود الخدمة بالخطأ في غضون ثلاثين (30) يوم عمل من تاريخ المعاملة، لا يتحمل مزود الخدمة أي التزام بتعديل هذه الرسوم، وتعتبر المعاملة صحيحة.
            </p>
            <p className='content-pr'>
                3-	حق لمزود الخدمة تعديل أسعار الخدمات بناءً على التقييم والمراجعة المستمرة لعمل التاجر، وذلك بموجب اشعار كتابي موجه اليك، وسيكون هذا التعديل ملزماً للتاجر إذا لم يقم بالاعتراض خلال ثلاثين (٣٠) يوم على التعديلات الجديدة.
            </p>

            <h2 className='sub-pr'>حدود مسؤولية: كورا</h2>
            <p className='content-pr'>
                1-	لن يكون مزود خدمة الدفع الالكتروني مسؤولاً عن أي خسارة أو ضرر (بما في ذلك الخسائر أو الأضرار الناتجة) في ذلك على سبيل المثال لا الحصر الخسارة أو الأضرار التي قد تلحق بك بسبب عدم عمل أي جهاز أو خدمة اتصالات.
            </p>
            <p className='content-pr'>
                2-	في حال حدوث أي عطل او انقطاع سوف يتخذ مزود خدمة الدفع الالكتروني جميع الخطوات اللازمة لتقليل المدة المترتبة من هذا الانقطاع.
            </p>
            <p className='content-pr'>
                3-	في حال حدوث أي فشل أو تأخير ناتج عن أسباب خارجة عن نطاق السيطرة او قوة قاهرة ودون خطأ أو إهمال لن يكون أي من الطرفين مسؤولا عن الأضرار غير المباشرة أو الخاصة أو التبعية الناتج عن ذلك.
            </p>

            <h2 className='sub-pr'>إدارة الحساب الخاص بالتاجر (لوحة التحكم)</h2>
            <p className='content-pr'>
                1-	من شروط فتح حساب مستخدم في بوابة كورا فتح وتسجيل حساب تجاري في أحد البنوك السعودية ليتم تسوية المبالغ المستحقة لك بعد خصم الرسوم وضريبة القيمة المضافة على رسوم خدمة الدفع الالكتروني.
            </p>
            <p className='content-pr'>
                2-	تتحمل وحدك مسؤولية الحفاظ على الأمان الكافي والتحكم في جميع المعرّفات أو كلمات المرور أو أي رموز. ويحق لمزود الخدمة الاعتماد على المعلومات التي تلقاها من قبلك ويفترض أن جميع هذه المعلومات قد تم نقلها من قبلك أو نيابة عنك.
            </p>
            <p className='content-pr'>
                3-	يجب أن تلتزم بجميع توصيات وإشعارات كورا المتعلقة بأمان المتجر الالكتروني، حساب هوية التاجر، وكلمة المرور وحساب او حسابات بوابة الدفع الالكتروني والمعمول بها خلال مدة الاتفاق بين الطرفين.
            </p>

            <h2 className='sub-pr'>استقبال المدفوعات من خارج المملكة العربية السعودية</h2>
            <p className='content-pr'>
                1-	عند رغبتك في استقبال المدفوعات من دول مجلس التعاون الخليجي أو دول العالم أو بهما معاً فإنه يتطلب الموافقة عليه من قبل مزود خدمة الدفع كورا وذلك للمخاطر الناجمة عنها، اذ يعتذر على كورا إتاحة استقبال المدفوعات من الدول المحظورة والدول عالية المخاطر ومن الدول التي تخضع لعقوبات دولية وإقليمية، حيث يتم رفع الطلب عبر التواصل المباشر مع مدير الحساب الخاص بالتاجر وتزويده بالمتطلبات و المستندات المطلوبة اذ يخضع طلب استقبال المدفوعات من خارج المملكة لدراسة من قبل قسم إدارة المخاطر والامتثال لدى مزود الخدمة، حتى يتم الاقرار فيما إن كان بالإمكان قبول الطلب أو رفضه على أساس النهج القائم على المخاطر، والدول المراد استقبال المدفوعات منها.
            </p>
            <p className='content-pr'>
                2-	يجب أن تلتزم بتوضيح سبب الرغبة في استقبال المدفوعات من خارج المملكة لمزود خدمة الدفع وشرح التالي بالتفصيل:
            </p>
            <p className='content-pr'>
                3-	المبالغ المتوقع استقبالها في فترة زمنية معينة، مبنية على أرقام مبيعات سابقة و/أو تصورات مستقبلية مدروسة.
            </p>
            <p className='content-pr'>
                4-	يجب عليك ذكر الدول المراد استقبال المدفوعات منها وتقديم طلب خاص موقع من قبله بإخلاء المسؤولية عن مزود الخدمة بكل ما يلحق عملية استقبال المدفوعات من خارج المملكة من مخاطر، تشمل مخاطر الاحتيال والتزوير على سبيل المثال لا الحصر.
            </p>
            <p className='content-pr'>
                5-	يخضع طلب استقبال المدفوعات من خارج المملكة لرسوم اضافية و الى دراسة من قبل قسم إدارة المخاطر والامتثال لدى مزود الخدمة، حتى يتم القرار فيما إن كان بالإمكان قبول الطلب أو رفضه على أساس النهج القائم على المخاطر، والدول المراد استقبال المدفوعات منها.
            </p>
            <p className='content-pr'>
                6-	يخضع حساب التاجر للرقابة وهو إجراء معتاد يتم النظر فيه لمبالغ العمليات وحجمها، والنظر أيضا للشكاوى المقدمة من العملاء على التاجر باختلاف أنواعها وعددها لا سيما شكاوى استرداد المبالغ.
            </p>
            <p className='content-pr'>
                7-	يجوز لك التراجع عن اتمام العلاقة التعاقدية خلال مدة عشر أيام عمل وبناء على ذلك لك ان تطلب من مزود الخدمة رد أي رسوم إدارية مفروضة عند التعاقد عدا الرسوم المرتبطة بخدمة مدفوعات تم تقديمها لك كتاجر.
            </p>

            <h2 className='sub-pr'>الإنذارات وتعليق الخدمة</h2>
            <p className='content-pr'>
                1- يجوز لك التراجع عن اتمام العلاقة التعاقدية خلال مدة عشر أيام عمل وبناء على ذلك لك ان تطلب من مزود الخدمة رد أي رسوم إدارية مفروضة عند التعاقد عدا الرسوم المرتبطة بخدمة مدفوعات تم تقديمها لك كتاجر.
            </p>
            <p className='content-pr'>
                2-	يجوز لمزود الخدمة تعليق الخدمة مؤقتاً في حال عدم وجود أي حركات على الحساب خلال مدة تسعين (90) يوماً وذلك بموجب اخطار كتابي موجه إلى التاجر.
            </p>
            <p className='content-pr'>
                3-	يجوز لك طلب إعادة تفعيل الحساب في حال اثبات ان ضمور الحركات تعود إلى طبيعة عمله.
            </p>

            <h2 className='sub-pr'>الإنهاء</h2>
            <p className='content-pr'>
                1-	في حال استمرارية تعليق الخدمة على التاجر وتجاوزها لمدة العقد المبرم محل تقديم الخدمة، يحق لمزود الخدمة انهاء العقد بشكل نهائي.
            </p>
            <p className='content-pr'>
                2-	عند رغبتك بتفعيل الخدمة بعد الانهاء، تلتزم بتزويد مزود الخدمة بجميع المتطلبات المفروضة لفتح الحساب مع توقيع اتفاقية جديدة.
            </p>
            <p className='content-pr'>
                3-	يجوز لك إنهاء هذه الخدمة في أي وقت بناءً على إشعار مزود الخدمة كتابياً قبل ثلاثين (٣٠) يوماً من الانهاء على أن تتحمل الرسوم المعلقة والمستحقات المالية محل تقديم الخدمة.
            </p>

            <h2 className='sub-pr'>التعويض</h2>
            <p className='content-pr'>
                يتعين عليك بموجب هذه الشروط والأحكام تعويض مزود الخدمة والتعهد بذلك ضد أي خسائر وأضرار وتكاليف والتزامات ومصاريف (بما في ذلك على سبيل المثال لا الحصر المصاريف القانونية وأي مبالغ تُدفع من قبل مزود الخدمة لطرف ثالث في تسوية دعوى قضائية أو نزاع بناء على مشورة من مستشاريه القانونيين او أي من المصاريف التي تكبدها أو تحملها ونجمت من إخلال من جانبك بأي نص من هذه الأحكام والشروط.
            </p>

            <h2 className='sub-pr'>التنازل</h2>
            <p className='content-pr'>
                بموجب الشروط والأحكام التي توافق عليها لا يجوز لك التنازل عن أي من الحقوق والالتزامات المترتبة عليك لأي جهة دون الحصول على موافقة كتابية مسبقة من مزود الخدمة، وفي حال عدم الموافقة المذكورة يكون ذلك التنازل -إذا تم- غير ملزم لمزود الخدمة ويحق لمزود الخدمة في هذه الحالة فسخ الاتفاقية والرجوع عليك بالتعويض عن أي ضرر يتكبده مزود الخدمة نتيجة تصرفك.
            </p>

            <h2 className='sub-pr'>الغرامات</h2>
            <p className='content-pr'>
                1-	فشلك في تقديم إجابة كافية بشأن المعلومات المتعلقة بالشراء خلال مدة يومين (٢) أيام عمل من تاريخ استلام الأسئلة والمطالبات.
            </p>
            <p className='content-pr'>
                2-	ادعاء حامل البطاقة بأن الشراء قد تم قبوله أو طلبه بصورة غير صحيحة دون تفويض منه.
            </p>
            <p className='content-pr'>
                3-	فشلك في التقيد بأي شرط من الشروط والاحكام هذه فيما يخص البضاعة أو الخدمات المقدمة من قبله أو عملية شراء قد تمثل خرقاً لأي نظام في المملكة العربية السعودية.
            </p>

            <h2 className='sub-pr'>حل الخلافات والقانون الواجب التطبيق</h2>
            <p className='content-pr'>
                1-	تخضع هذه الشروط والاحكام وتفسر وفقاً للقوانين والأنظمة الواجبة التطبيق والسارية في المملكة العربية السعودية.
            </p>
            <p className='content-pr'>
                2-	في حال وجود نزاع او خلاف، ينبغي على كلا الطرفين السعي الى حله وديا خلال تسعين (90) يوماً عبر التفاوض قبل اللجوء الى الاجراءات القانونية.
            </p>
            <p className='content-pr'>
                3-	في حال الإخفاق في حل الخلافات ودياً بعد المدة المحددة، يتم اللجوء الى المحاكم المختصة في المملكة العربية السعودية في مدينة الرياض.
            </p>

            <h2 className='sub-pr2'>أحكام ختامية</h2>
            <p className='content-pr'>
                1- تخضع هذه الاتفاقية وكافة الالتزامات الناشئة عنها أو المتعلقة بها لأنظمة المملكة العربية السعودية
            </p>
            <p className='content-pr'>
                2- في حال مخالفتك لأي بند من بنود هذه الاتفاقية، فيحق لكورا تعليق حسابك، أو إنهائه، أو رفض أي وصول أو استخدام منك للخدمة في أي وقت من الأوقات، ولن يكون لك الحق في المطالبة بأي تعويضات، كما يحتفظ كورا بحقه القانوني بمطالبتك بأية تعويضات عن أي ضرر مباشر أو غير مباشر يلحق به، أو بأحد مستخدميه.
            </p>
            <p className='content-pr'>
                3- تقوم كورا بالتواصل معك من خلال الوسائل الالكترونية، كالبريد الإلكتروني أو الرسائل النصية أو إخطارات التطبيقات الداخلية أو المراسلات على الموقع الإلكتروني. وتعدّ كافة الاتفاقيات أو الإخطارات أو الإفصاحات أو المراسلات الأخرى التي نرسلها لك إلكترونياً مستوفية لأي متطلبات قانونية.
            </p>
            <p className='content-pr'>
                4-  يمكنك التواصل معنا من خلال البريد الالكتروني Hi@cora.sa
            </p>

        </div>
    );
}

export default PrivacyPolicy;