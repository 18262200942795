import React, { useState } from 'react';
import './EmailSub.css'; // Import the CSS for styling

const EmailSub = () => {

    const [email, setEmail] = useState('');  // State to store the email
    const [successMessage, setSuccessMessage] = useState('');  // State for success message

    const handleSubmit = (e) => {
        e.preventDefault();

        // Google Apps Script Web App URL
        const scriptURL = 'https://script.google.com/macros/s/AKfycbw2kBvxpX5qDT_B_peeMJRh7tBdTwL45CV8QvpDgTRy5cQgRsX5Sc4um4F4V--97B-J/exec';

        // Create form data
        const formData = new FormData();
        formData.append('email', email);  // Add the email field

        // Send the form data to the Google Apps Script
        fetch(scriptURL, {
            method: 'POST',
            body: formData
        })
            .then(response => response.text())
            .then(result => {
                setSuccessMessage('تم التسجيل بنجاح');  // Display success message
                setEmail('');  // Clear the input field
                setTimeout(() => setSuccessMessage(''), 3000);  // Clear the message after 3 seconds
            })
            .catch(error => console.error('Error!', error.message));
    };


    return (
        <div className="subscription-container">
            <div className="email-subscription">
                <h2>اشترك في النشرة البريدية</h2>
                <form className="email-form" onSubmit={handleSubmit}>
                    <input
                        type="email"
                        placeholder="Email"
                        className="email-input"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}  // Update email state
                        required
                    />
                    <button type="submit" className="subscribe-btn">
                        اشترك
                    </button>
                </form>
                {successMessage && <p className="success-message">{successMessage}</p>}
            </div>
        </div>

    );
};

export default EmailSub;
