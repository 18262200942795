import React from 'react';
import { useNavigate } from 'react-router-dom';
import './sectionTwo.css';

function SectionTwo() {

    const navigate = useNavigate();

    const handleLinkClick = () => {
        navigate('/CoraLink');
    };

    const handleBillClick = () => {
        navigate('/CoraBilling');
    };

    return (
        <div className="section-two-container">
            <h1 className="section-title">
                <label className='betaMain'>  حلول  ●</label>
                <br />
                <span className="subtitle">حلول مدفوعات مبتكرة
                    مصممة لاعمالك</span>
            </h1>

            <div className="features-container">

                <div className="gatefeature">
                    <div className="text-container">
                        <h2 className="textGate">بوابة المدفوعات</h2>
                        <p>خيارات مرنة لإستقبال المدفوعات. تعمل على تعزيز وتحسين تجربة المستخدم</p>
                    </div>
                    <div className="icon-container">
                        <img className="gated" src="/payment-protection.png" alt="Share Icon" />
                    </div>
                </div>

                <div className="userFeature">
                    <div className="text-container" >
                        <h2 className="textUser">ادارة مالية</h2>
                        <p>تتبع العمليات والتحكم في الحسابات. تحليلات شاملة حول المدفوعات وسلوك المستخدمين</p>
                    </div>
                    <div className="icon-container">
                        <img className="userd" src="/user.png" alt="Create Icon" />
                    </div>
                </div>

                <div className="linksfeature">
                    <div className="text-container" >
                        <h2 className="textLink">طرق دفع متعددة</h2>
                        <p>طرق دفع متعددة لتحسين تجربة المستخدم، مثل كورا باي لحلول المدفوعات عند الاستلام</p>
                    </div>
                    <div className="icon-container">
                        <img className="linked" src="/link.png" alt="Create Icon" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SectionTwo;
