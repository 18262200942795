import React from 'react';
import './Featuers.css';

function Features() {
    return (
        <div className="Featuers-section">

            <label className='betaMain'>  مزايا  ●</label>
            <h1 className="main-title">حلول ومزايا المدفوعات في
                مكان واحد</h1>
            <p className="main-subtitle">خيارات مرنة تناسب احتياجات عملك لاستقبال المدفوعات تقنيًا أو بطريقة مباشرة
                نحن هنا لمساعدتك في تسهيل عمليات مدفوعاتك وتحقيق أهدافك</p>

            <div className="Featuers-container">

                <div className="Featuers-card">
                    <img src="pay.png" alt="Feature Image" className="feature-image" />
                    <h2>نعمل مع</h2>
                    <p>طرق دفع متنوعة ومتعددة وسهلة الاستخدام تناسب احتياجات العملاء</p>
                </div>

                <div className="Featuers-card">
                    <img src="code.png" alt="Feature Image" className="feature-image" />
                    <h2>اهتمام بالمطورين</h2>
                    <p>أبسط الادوات واساليب الربط بعيداً عن التعقيد</p>
                </div>

                <div className="Featuers-card">
                    <img src="dash.png" alt="Feature Image" className="feature-image" />
                    <h2>لوحة تحكم سهلة الاستخدام</h2>
                    <p>توفر لوحة التحكم واجهة سهلة لإدارة المستخدمين والمدفوعات، مما يمنحك رؤية شاملة وتحكم كامل في جميع العمليات</p>
                </div>

                <div className="Featuers-card">
                    <img src="linnk.png" alt="Feature Image" className="feature-image" />
                    <h2>طرق متنوعة لاستقبال المدفوعات</h2>
                    <p>حلول دفع مرنة تضمن جودة إدارة التدفقات المالية، تتيح لك استقبال المدفوعات بطرق متعددة تلبي احتياجات عملائك</p>
                </div>

            </div>
        </div>
    );
};

export default Features;
