import React from 'react';
import './BuildFor.css'; // Assuming you have a CSS file for styling

const BuildFor = () => {
    return (
        <div className="build-for-container">
            <label className='betaMain'>  متكامل مع حلول الدفع  ●</label>
            <h1 className="build-title">انطلق إلى آفاق أبعد وبسرعة أكبر</h1>
            <p className="build-subtitle">إطلق أعمالك بسرعة، حلول تتكيف مع نموك وحلول المدفوعات وأتمتة عملياتك لتحقيق المزيد بموارد أقل</p>

            <div className="Featuers-build">

                <div className="Featuers-buildCard">
                    <h2>إدارة وتحليلات مالية</h2>
                    <p>أدوات لإدارة مدفوعاتك مع تحليلات مالية، تزوّدك برؤى واضحة حول أدائك المالي وتدعم قراراتك الاستراتيجية</p>
                </div>

                <div className="Featuers-buildCard">
                    <h2>إشعارات تلقائية</h2>
                    <p>إشعارات تلقائية قبل وعند موعد الاستحقاق لضمان متابعة المدفوعات بانتظام، مما يساعد على تحسين تجربة العملاء وزيادة ولائهم، بالإضافة إلى تعزيز تدفق الإيرادات بانتظام</p>
                </div>

                <div className="Featuers-buildCard">
                    <h2>واجهة ادارة المدفوعات</h2>
                    <p>صفحة مخصصة لإدارة المدفوعات، وخصص تصميمها لتتناسب مع هوية علامتك التجارية، مجهزة وسهلة الاضافة لمنصتك</p>
                </div>

                <div className="Featuers-buildCard">
                    <h2>كورا باي</h2>
                    <p> خدمة الدفع عند الاستلام بمرونة إضافية تتيح للعملاء الدفع على دفعتين؛ دفعة عند الطلب ودفعة عند الاستلام، مما يعزز الثقة ويسهل إتمام المعاملات</p>
                </div>

            </div>
        </div>
    );
};

export default BuildFor;
